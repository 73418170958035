import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "如何诵经"
    }}>{`如何诵经`}</h1>
    <p>{`　　`}<strong parentName="p">{`第一，念经时，我们一般眼睛看，嘴巴念，耳朵听。`}</strong>{`很多人就注重嘴巴念，和眼睛看，却忽略了耳朵听。其实最关键的，因为是耳朵听，也就是诵经时要把心放在耳根上，认真的听，不是放在嘴巴上拼命的念，念不念没有多大的关系，但是听不听关系就大了。为什么呢，因为人的耳根最历，经历了耳根就可以永世不忘，但是眼根就没有这个功德。在楞严经中讲到耳根有一千二百功德，眼根只有八百功德，而且佛陀劝导我们要多修耳根圆通，是最厉害最好修的法门。`}</p>
    <p>{`　　`}<strong parentName="p">{`第二，念经时嘴巴上要舌头动，嘴巴不动。`}</strong>{`很多人念经很大声，诵完一部经，声音就沙哑了，那是因为嘴巴一直动的缘故。有没有发现念经的声音都是比较平和，不会大不会小，就是缓缓地，感觉也有点咬字不清。这就是嘴巴不动舌头动的结果。当然，要达到嘴巴不懂舌头动，关键的是气出在丹田，而不是出在肺部，更不是出在喉咙。丹田出来的气是圆融的，肺部出来的气，是比较尖，喉咙出来的气，更是嘶哑的。所以要多练气。`}</p>
    <p>{`　　那么如何练气呢，就是先念“南无释迦牟尼佛”，可以去试一试，念每个字时感受一下肚子在震动，试着从肚子发气。多试一试。然后气就比较顺畅了，这样无论唱诵多少，都不会觉得累。`}</p>
    <p>{`　　`}<strong parentName="p">{`第三，最好把经书背下来，同时用师父念经的调，不缓不慢。有些规律的。`}</strong>{`诵经是一种很好的享受，不是像菩萨交差。尤其是持咒时，更要用以上的方式，比如大悲咒，就很好听。而且可以收摄身心。很不可思议的。同时可以震动我们身上的气脉，让身上的每个细胞都感受很愉悦。不知道大家有没有这种感觉。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      